/* .Note{
    background:#fff;
    border-radius: 7px;
    box-shadow: 0px 2px 5px #ccc;
    padding:10px;
    width:240px;
    margin:16px;
    float:left; 
}

.Note h1{
    font-size: 1.1em;
    margin-bottom:6px;
}

.Note p{
    font-size: 1.1em;
    margin-bottom:10px;
    white-space: pre-wrap;
    word-wrap: break-word;
} */
.pinBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 5px;
  border-bottom: 1px solid #f5ba13;
}
.note {
  width: 28em;
}
.note h1 {
  padding-top: 20px;
  font-weight: 700;
}

.note p {
  text-align: justify;
}
